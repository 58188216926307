export interface FAQDict {
    key: string,
    value: FAQ[]
}

export interface FAQ {
    question: string;
    answer: string;
}

export class TextStrings {

    static submitPrivacyDisclaimerText: string = `
    <p>Please note only one application is required to be submitted per client even if you have multiple joint loans with other clients.</p>
    <p>You will not be able to update your application details after submission, so take the time now to verify the information you have provided and make any necessary changes.</p>
    <p>To ensure secure delivery of any remediation payments owed to you, we need to verify your information. By providing it through our portal, you’re helping us process your application. We take your privacy seriously, and your information will be handled in accordance with <a href="https://kpmg.com/nz/en/home/misc/privacy.html">KPMG’s privacy statement</a>.</p>
    <p>For any questions, please submit a support request by clicking Contact Us at the top right.</p>  
    `

    static pageNotFoundText: string = `
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div class="mat-display-1">Page not found</div>
    <h4>It's possible that the page you were looking for may have been moved, updated or deleted.</h4>
    </div>
    `
    static homeTitle : string =`Client Remediation at NBS New Zealand`

    static homeOverview: string = `This site referred to as ‘the client portal’, provides you with information about the remediation process initiated by NBS - Nelson Building Society. 
    If you have received notification of a remediation, please follow the instructions below on how to register online and apply for a remediation payment.`

    static general: FAQ[] = [
        {
            question: `What has happened?`,
            answer: `
           <p>In 2023 NBS initiated a proactive review into our loan-related systems and processes to ensure these were effective.</p>
           <p>We discovered that in some instances the interest charges generated by our system do not correctly align with the terms of the loan agreements for a particular group of loans. Interest charges on some loans were calculated based on a monthly repayment schedule.  This means that if you made weekly or fortnightly repayments, or made additional repayments, the reduced loan balance was not always recognised in calculating the daily balance owing, and therefore unfortunately additional interest was charged.  If you made monthly repayments, your loan may also be impacted, but to a lesser degree.</p>
           <p>Where your loan was open between 1 April 2020 and 31 August 2023, and was subject to the Credit Contracts and Consumer Finance Act (CCCFA) you may have been contacted by us previously as we identified that in some instances we did not send written details of changes you made to your loan or do not have a record of having sent these details. In addition, where we did send written details, what we provided may have been incomplete, inaccurate, or late.</p>
           <p>We’re sorry this has occurred. To make it right, we're going to make a payment to affected borrowers.</p>
            `
        },
        {
            question: `Who’s impacted?`,
            answer: `
            <p>These remediation initiatives are for closed loans only, where it has been identified that additional interest has been charged, or where you had previously an active loan with NBS New Zealand between 1st April 2020 and 31st August 2023, and you may have been impacted due to a requested loan change.</>
            <p>Please note, not every loan client is entitled to a remediation payment.</p>
            `
        }
    ]

    static registeringanapplication: FAQ[] = [
        {
            question: `How do I register and submit an application?`,
            answer: `<p>You can register using a valid email address and setting up a password. You will receive an email verification code as part of additional security check before proceeding. To submit an application via the portal, you will need:<p><ul>
            <li><b>Proof of identity:</b> A New Zealand driver’s license, copy of your birth certificate, New Zealand or overseas passport, certificate of citizenship, immigration NZ visa, or certified 18+ card. Please note if your identity document has two sides both sides must be provided.</li>
            <li><b>Proof of bank account into which you wish your remediation to be paid:</b> This is any bank generated document that contains your name and bank account number. This may be a bank statement, deposit slip or screenshot of your online banking. Whichever form of evidence you choose will need to show the bank’s logo and your full account number and name of the account (which must be held by you or jointly held by you).</li>
            <li><b>NBS Client Number:</b> You will need your client number to validate you as an NBS loan client. This number can be found on the communication sent by us to all impacted loan clients via email or post.</li>
            <li><b>Change of name:</b> If you have changed your name since your loan with NBS ended, you will also need to provide proof of this name change, for example, a marriage certificate.</li>
            </ul></p>
            <p>After you have registered and submitted your completed application, our remediation team will validate your details. Once your application has been validated, we will let you know if you are eligible for a remediation payment and, if so, when you can expect to receive it.</p>`
        },
        {
            question: `Do I need an email address to sign-up?`,
            answer: `<p>Yes, you require an email address to raise an application via the portal. This is required to authenticate applicants and notify applicants of updates to their application. If you do not have an email address and cannot raise an application via the portal, please call <a href="tel:+640800101700">0800 101 700</a>`
        },
        {
            question: `Why do I have to provide proof of bank account?`,
            answer: `Proof of bank account needs to be provided so we can make sure that any payment you may be entitled to goes into the correct account.`
        },
        {
            question: `My bank account hasn’t changed since my loan expiry with NBS – do I still need to provide these details?`,
            answer: `Yes, as your loan is no longer active, we need you to confirm your current bank account details.`
        },
        {
            question: `Can one user make multiple claims?`,
            answer: `
            <p>No, only one application can be associated with an email address. Once a client number has been associated with an application that has been successfully validated, any other applications attempting to use the same client number will be returned to the client with an error advising that this client number has already been used.</p>
            <p>If you have an earlier remediation payment(s) due to you via this portal you only need to make one application in the portal to receive all your payments.</p>
            `
        },
        {
            question: `How are payments made for loans in joint or multiple names?`,
            answer: `Remediation amounts are made per loan, but for loans in joint or multiple names, the amount will be split equally between borrowers. E.g. for loans in 2 names, each party will receive 50% of the loan remediation payment. This amount is detailed in the communication we have forwarded to all affected borrowers.`
        },
        {
            question: `Do all borrowers need to submit a separate application?`,
            answer: `All borrowers need to submit their own application via the portal to receive their remediation payment.`
        },
        {
            question:`What do I do if I do not have access to a computer?`,
            answer:`Please call <a href="tel:+640800101700">0800 101 700</a>`
        },
        {
            question:`Why was my application declined?`,
            answer:`Your application via the portal may be declined if you haven’t submitted all the mandatory identity and validation proof documents needed to process your request. We will let you know what is needed to complete an application.`
        },
        {
            question:`What are the acceptable file types for uploading supporting documents?`,
            answer:`Only file types ending with .pdf, .jpg, .png, .gif and .tif are acceptable as supporting proof documents. You can upload maximum of 4 supporting documents`
        }
    ]

    static paymentquestions: FAQ[] = [
        {
            question: `How long after I’ve been approved will I receive payment?`,
            answer: `
            <p>After your application has been accepted, we will confirm the bank account you have nominated for the payment and provide you an email update when you can expect the payment to be made.</p>
            <p>We anticipate payments will be processed within one month of your application.  Payments will be referenced to either the loan account detailed in the application or your client number so you can easily identify the transaction.</p>
            <p>Note, payments can only be processed to NZ bank accounts.</p>`
        },
        {
            question: `If I am entitled to a remediation payment, can I get my payment paid into more than one bank account?`,
            answer: `No, remediation payments can only be made to one nominated bank account provided by you in your application.`
        },
        {
            question: `How do I know when I’ve been paid?`,
            answer: `Your portal application status will be updated to ‘Paid’ when the remediation payment has been processed by us.`
        }
    ]

    static taxkiwisaverquestions: FAQ[] = [
        {
            question: `Will I have to pay tax on my remediation payment?`,
            answer: `The payment might have tax implications (for example on your tax, KiwiSaver, working for families and WINZ allowances). We recommend that you seek independent professional advice as NBS is unable to provide any financial and tax advice on this matter.`
        },
        {
            question: `How do I donate the payment to KidsCan?`,
            answer: `If you wish to donate your remediation payment to KidsCan you do not need to register with the portal. You just need to email <a href='mailto:info@nbs.co.nz'>info@nbs.co.nz</a> your name, client number and ‘please donate’ in the subject line.`
        }
    ]

    static otherquestions: FAQ[] = [
        {
            question: `Who can I speak to if I have more questions or would like more information?`,
            answer: `If you have further questions or would like more information, please call us on <a href="tel:+640800101700">0800 101 700</a> or login and click the ‘Contact Us’ button at the top of the page.`
        }
    ]

    static faqs: FAQDict[] = [
        {
            key: "General",
            value: TextStrings.general
        },
        {
            key: "Registering an application ",
            value: TextStrings.registeringanapplication
        },
        {
            key: "About Remediation Payments",
            value: TextStrings.paymentquestions
        },
        {
            key: "Tax & KiwiSaver",
            value: TextStrings.taxkiwisaverquestions
        },
        {
            key: "Support and more information",
            value: TextStrings.otherquestions
        } 
    ]


    static overviewText: string = `

    <p><b id="questions">How do I apply for a remediation payment?</b></p>
    <p>To submit an application for payment via the portal, you will need:</p>
    <ul>
        <li><b>Proof of identity:</b> A current NZ driver’s license, copy of birth certificate, New Zealand or overseas Passport, certificate of citizenship, immigration NZ visa, or certified 18+ card. Please note if your identity document has two sides both sides must be provided.</li>
        <li><b>Proof of bank account into which you wish your remediation to be paid:</b> This is any official bank generated document that contains bank logo, your name and bank account number. This may be a bank statement, deposit slip or screenshot of your online banking.</li>
        <li><b>NBS Client Number:</b> You will need your client number to validate you as an NBS loan client. This number can be found on the communication sent to you via email or post.</li>
    </ul>
    <p>You can register using a valid email address and setting up a password. Once you have completed your registration you will receive an email verification code as part of additional security check before proceeding.</p>
    <p>To register and create your portal login please click <a href="#" class="open-login">here</a> or alternatively Login/Register on the top right of this page to start your application.</p>
    <p>You only need to register once to access all remediation payments due.</p>
    <p><b id="questions">What happens after I submit my application?</b></p>
    <p>After you have submitted your application, we will validate your details and will contact you to either:</p>
    <ul>
        <li>confirm approval of your application and that a payment will be made to the bank account you have nominated and when this will occur or;</li>
        <li>if we require further information.</li>
    </ul>
    <p>If you wish to correct or change any details you have already provided through your online application, please get in touch via the ‘contact us’ form in the online portal <a href="#" class="open-support">here</a>.</p>
    <p>For further details please see the <a href="#" class="open-faqs">FAQs</a>.</p>
    <p><b id="privacyHeader">This remediation client portal by NBS is supported by KPMG.</b></p>
    <p><b id="privacyHeader">Information about privacy</b></p> 
    <p id="privacyInfo">To ensure secure delivery of any remediation payments owed to you, we need to verify your information. By providing your information in our secure portal, you’re helping us process your application quickly. We take your privacy seriously, and your information will be handled in accordance with <a href="https://kpmg.com/nz/en/home/misc/privacy.html">KPMG’s privacy statement</a>.</p>
    <p id="privacyInfo">By using this site, you are agreeing to these <a href='https://kpmg.com/nz/en/home/misc/legal.html'>Terms of Use.</a></p>
    `
    
    static aboutThisProcessText: string = ``


    static importantNotesText: string = ``


    static kiwisaverAdvancedQuestions: boolean = true;

    static employmentAdditionalDetails: boolean = false;

    static applicationIntroText: string = `<p>To submit an application, you will need to complete the application form and provide relevant documentation so we can validate your identity and personal details. It is important that you carefully review your details before submitting them as the information you provide will be compared against our client records to confirm your identity.</p>
  <p>Once your application has been submitted, you cannot withdraw it.</p>`;

    static deceasedEstateInfo: string = `<p>Anyone applying on behalf of a deceased estate will need to provide evidence of probate or a letter of administration confirming that they are authorised to act on behalf of the client or their estate. The estate executor or administrator will also need to provide proof of ID, a death certificate for the client, and complete relevant documents for the deceased client's estate.</p>
    <p>Please upload these documents under Proof of Identity.</p>
  <p>If you are unsure of how to complete the application please ‘Contact Us’ form in the online portal.</p>`;

    static supportingDocumentsHints: {[key: string]: string} = {
        proofOfIdentity: "Please upload eligible proof of identity. This can be a current NZ driver’s license, copy of birth certificate, New Zealand or overseas Passport, certificate of citizenship, immigration NZ visa, or certified 18+ card. If providing a current NZ driver's license, please provide ensure both sides of your license are uploaded.",
        proofOfBankAccount: "This is any document that contains your name and bank account number. This may be a bank statement, deposit slip, or screenshot of your online banking. Whichever form of evidence you choose will need to show the bank's logo and your full account number and name of the account (which must be held by you or jointly held by you).",
    }
    
}